import { Box, Button, Paper, Typography } from '@mui/material';
import EmailSentLogo from '../../utils/images/emailSent.svg';
import PasswordCreatedLogo from '../../utils/images/passwordCreated.svg';
import PasswordUpdatedLogo from '../../utils/images/passwordUpdated.svg';
import { useNavigate } from 'react-router-dom';
export const SuccessPage = () => {
  const query = window.location.search;
  const queryObject = new URLSearchParams(query);
  const navigate = useNavigate();
  const originURL = queryObject.get('origin');
  const continueURL = queryObject.get('continue');
  const from = queryObject.get('from');
  const projectID = queryObject.get('id');

  const navigateToLogin = () => {
    if (continueURL) {
      navigate(
        `/login?origin=${originURL}&continue=${continueURL}&id=${projectID}&version=v2`,
      );
    } else {
      navigate(`/login`);
    }
  };

  const boxStyle = {
    boxShadow: 'none', // This removes the border
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Paper
          elevation={3}
          sx={{
            padding: 4,
            backgroundColor: '#0000',
            alignItems: 'center',
          }}
          style={boxStyle}
        >
          <>
            <img
              src={
                from === 'verify'
                  ? EmailSentLogo
                  : from === 'reset'
                  ? PasswordUpdatedLogo
                  : PasswordCreatedLogo
              }
              alt="Elevade Email Success"
              className="center-image"
            ></img>
            {from === 'verify' ? (
              <>
                <Typography
                  textAlign={'center'}
                  fontFamily={'Gotham-Medium'}
                  fontSize={24}
                  component="p"
                >
                  Verification email sent!
                </Typography>
                <Typography
                  sx={{
                    fontFamily: 'Gotham-book',
                    fontSize: 16,
                    mb: 1,
                    paddingTop: 1,
                    textAlign: 'center',
                  }}
                >
                  We've sent you a link to verify your account. Please click on the link
                  in your email to get started.
                </Typography>
              </>
            ) : (
              <>
                <Typography
                  textAlign={'center'}
                  fontFamily={'Gotham-Medium'}
                  fontSize={24}
                  component="p"
                >
                  {from === 'verify'
                    ? 'Verification email sent!'
                    : from === 'reset'
                    ? 'Password updated'
                    : 'Password created!'}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: 'Gotham-book',
                    fontSize: 16,
                    mb: 1,
                    paddingTop: 1,
                    textAlign: 'center',
                  }}
                >
                  You have created a new password for ELEVADE.
                  <br />
                  Please use your new password to log in to your account.
                </Typography>
              </>
            )}
            <Button
              variant="contained"
              type="submit"
              sx={{
                fontFamily: 'Gotham-Medium',
                fontSize: 15,
                marginTop: 6,
                marginBottom: 2,
                marginLeft: 'auto',
                marginRight: 'auto',
                height: 56,
                width: 400,
                backgroundColor: '#088FD1',
                textTransform: 'none',
                display: 'block',
              }}
              onClick={navigateToLogin}
            >
              {from === 'verify' ? 'Go to Main Page' : 'Log In Now'}
            </Button>
          </>
        </Paper>
      </Box>
    </>
  );
};
