import {
  Alert,
  Box,
  Button,
  LinearProgress,
  Paper,
  TextField,
  Typography,
  Link,
} from '@mui/material';
import axios from 'axios';
import { useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useNavigate } from 'react-router-dom';

export const FirstTimeLogin = () => {
  const navigate = useNavigate();

  const [error, setError] = useState('');
  const [isVerified, setVerified] = useState(false);

  const query = window.location.search;
  const queryObject = new URLSearchParams(query);

  const originURL = queryObject.get('origin');

  // TODO, the origin url is not required to reset the password, but if we want to redirect the user, we need that url

  const email = useRef<any>(null);

  const [forgotPasswordLoading, setForgotPasswordLoading] = useState<boolean>(false);
  const [sendPasswordSuccess, setSendPasswordSuccess] = useState<boolean | null>(null);
  const handleRecaptchaChange = () => {
    setVerified(true);
  };
  const validateInput = () => {
    if (!email.current || email.current === '') {
      setError('Please enter the Username');
      return false;
    }

    // Validate email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email.current)) {
      setError('Please insert a valid email address');
      return false;
    }

    setError('');
    return true;
  };
  const sendFirstTimeLoginLink = (evt: any) => {
    evt.preventDefault();

    if (validateInput() && isVerified == true) {
      // Proceed with form submission or
      setForgotPasswordLoading(true);

      axios
        .post(`${process.env.REACT_APP_AUTH_GS_BASE_URL}/api/v1/auth/first-time-login`, {
          email: email.current,
          originURL: originURL,
        })
        .then((res) => {
          //setSendPasswordSuccess(true);
          navigate(`/success-page?from=verify&origin=${originURL}`);
        })
        .catch((err) => {
          let error_message =
            'No account found with this email. Try a different email or contact your administrator';
          if (err?.response?.data) {
            const errData = err.response.data;
            if (errData?.detail?.msg) {
              error_message = errData.detail.msg;
            }
          }
          setError(error_message);
          //   setSendPasswordSuccess(false);
        })
        .finally(() => {
          setForgotPasswordLoading(false);
        });
    }
  };

  const boxStyle = {
    boxShadow: 'none', // This removes the border
    backgroundColor: '#0000',
    padding: 15,
    marginTop: 15,
  };
  const isDisableIfSendPasswordLinkSuccess =
    sendPasswordSuccess === null ? false : sendPasswordSuccess;
  const showSendLinkBtn = sendPasswordSuccess !== true;

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: 2,
        }}
      >
        {sendPasswordSuccess === true ? (
          <Alert severity="success" sx={{ mb: 2 }}>
            Reset password link sent, check your inbox!
          </Alert>
        ) : (
          sendPasswordSuccess === false && (
            <Alert severity="error" sx={{ mb: 2 }}>
              Failed to send password link, please try again!
            </Alert>
          )
        )}
        <div>
          <Typography
            textAlign={'center'}
            fontFamily={'Gotham-Medium'}
            fontSize={24}
            component="p"
          >
            First time login?
          </Typography>

          <Typography
            sx={{
              fontFamily: 'Gotham-book',
              fontSize: 16,
              mb: 1,
              paddingTop: 1,
              paddingLeft: 7,
              paddingRight: 7,
              textAlign: 'center',
            }}
          >
            Enter your organization email address to get started. We will send you a link
            to click to confirm your account via email.
          </Typography>
        </div>
        <Box>
          <Paper
            elevation={3}
            sx={{
              width: 400,
            }}
            style={boxStyle}
          >
            <>
              <form onSubmit={sendFirstTimeLoginLink}>
                <Typography sx={{ fontFamily: 'Gotham-book', fontSize: 16, mb: 1 }}>
                  Organization Email
                </Typography>
                <TextField
                  //   ref={email}
                  name="email"
                  id="email"
                  variant="outlined"
                  fullWidth
                  error={error ? true : false}
                  helperText={error ? error : ''}
                  disabled={isDisableIfSendPasswordLinkSuccess}
                  inputProps={{
                    style: {
                      fontFamily: 'Gotham-book',
                      fontSize: '15px',
                      borderRadius: '5px',
                      backgroundColor: 'white',
                    },
                    placeholder: 'Email address',
                  }}
                  onChange={(evt) => {
                    email.current = evt.target.value;
                    setError('');
                  }}
                />
                {showSendLinkBtn && (
                  <>
                    {' '}
                    <div
                      style={{
                        paddingTop: '34px',
                        width: '100%',
                        alignItems: 'center',
                      }}
                    >
                      <ReCAPTCHA
                        sitekey="6Lflhg4pAAAAACyxCbvGMmGVrSs43olUhC-972RG"
                        onChange={handleRecaptchaChange}
                        className="recaptcha-center"
                      ></ReCAPTCHA>
                    </div>
                    <Button
                      variant="contained"
                      fullWidth
                      type="submit"
                      sx={{
                        fontFamily: 'Gotham-Medium',
                        fontSize: 15,
                        marginTop: 4,
                        marginBottom: 2,
                        height: 56,
                        backgroundColor: '#088FD1',
                        textTransform: 'none',
                      }}
                      disabled={forgotPasswordLoading || !isVerified}
                    >
                      Verify Account
                    </Button>
                  </>
                )}
                {/* TODO > show this loading when click send link button above  */}
                {forgotPasswordLoading && (
                  <Box sx={{ width: '100%', mb: 4, mt: -3 }}>
                    <LinearProgress sx={{ height: 2 }} />
                  </Box>
                )}
              </form>
            </>
          </Paper>
        </Box>
        <Typography
          sx={{
            fontFamily: 'Gotham-Book',
            color: '#838383',
            fontSize: 12,
            mb: 4,
            textAlign: 'center',
            marginTop: '32px',
            marginBottom: '2px',
          }}
        >
          By entering your email, you confirm to have read ELEVADE’s
        </Typography>
        <Typography
          sx={{
            fontFamily: 'Gotham-Book',
            color: '#838383',
            fontSize: 12,
            mb: 4,
            textAlign: 'center',
            marginBottom: '2px',
          }}
        >
          agree to ADE's{' '}
          <Link
            style={{ color: '#00A8E8', textDecoration: 'none' }}
            href={`https://ade.aero/terms-of-use`}
            target="_blank"
            rel="noreferrer"
          >
            Terms of Use
          </Link>
          &nbsp;and&nbsp;
          <Link
            style={{ color: '#00A8E8', textDecoration: 'none' }}
            href={`http://ade.aero/privacy-statement`}
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy{' '}
          </Link>
        </Typography>
        {/* NOTE: this should be from login button from the Application that subscribe to AUTH GS */}
        {/* <Typography sx={{ color: '#565656', fontSize: 14, mt: 2 }}>Already have account,  <a href={`/login${query}&type=SIGNUP`} style={{ textDecoration: 'underline', cursor: 'pointer', color: 'blue' }}>Sign In</a> now</Typography> */}
      </Box>
    </>
  );
};
